<template>
  <div 
    class="sf-carousel"
  >  
    <div 
    class="sf-carousel__wrapper"
    :class="isLoaded ? 'loaded': 'loading'"
    >
      <div ref="glide" class="glide">
        <div class="glide__track" data-glide-el="track">
          <ul class="glide__slides sf-carousel__slides">
            <slot />
          </ul>
        </div>
      </div>
      <slot name="prev">
        <SfButton
          v-if="isLoaded"
          aria-label="previous"
          class="sf-arrow carousel-prev-button"
          @click="go('prev')"
        >
          <SfIcon
            icon="chevron_left"
            size="xs"
            color="black"
            viewBox="0 0 24 24"
            :coverage="1"
          />
        </SfButton>
      </slot>
      <slot name="next">
        <SfButton
          v-if="isLoaded"
          aria-label="next"
          class="sf-arrow carousel-next-button"
          @click="go('next')"
        >
          <SfIcon
            icon="chevron_right"
            size="xs"
            color="black"
            viewBox="0 0 24 24"
            :coverage="1"
          />
        </SfButton>
      </slot>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { SfButton, SfIcon } from '@storefront-ui/vue';
import CarouselItem from "./CarouselItem.vue";
import Glide from "@glidejs/glide";

Vue.component("CarouselItem", CarouselItem);
export default {
  name: "Carousel",
  components: {
    SfIcon,
    SfButton
  },
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      glide: null,
      isLoaded: false,
      defaultSettings: {
        type: 'carousel',
        perView: 1,
        breakpoints: {}
      },
    };
  },
  computed: {
    mergedOptions() {
      let breakpoints = { ...this.defaultSettings.breakpoints };
      if (this.settings.breakpoints) {
        breakpoints = { ...breakpoints, ...this.settings.breakpoints };
      }
      return {
        ...this.defaultSettings,
        ...this.settings,
        breakpoints: breakpoints,
      };
    },
  },
  mounted: function () {
    this.$nextTick(() => {
        window.addEventListener('resize', this.onResize)
        this.initSlider();
    });
  },
  updated: function () {
    window.addEventListener('resize', this.onResize)
    if (! this.glide) {
      this.initSlider();
    }
  },
  methods: {
    go(direct) {
      if (!this.glide) return;
      switch (direct) {
        case "prev":
          this.glide.go("<");
          break;
        case "next":
          this.glide.go(">");
          break;
      }
    },
    onResize() {
      if (this.glide) {
        try {
          this.glide.destroy();
        } catch (error) {
          //
        }
      }
      
      this.initSlider();
    },
    initSlider() {
      if (!this.$slots.default) return;
      if (window.innerWidth >= 561 && typeof this.$refs.glide !== 'undefined') {

        const glide = new Glide(this.$refs.glide, this.mergedOptions);
        const size = this.$slots.default.filter((slot) => slot.tag).length;        
        const breakpointKeys = Object.keys(glide.settings.breakpoints);
        let numberOfSlidesPermitted = glide.settings.perView;

        breakpointKeys.forEach(key => {
          if (Number(key) < window.innerWidth) {
            numberOfSlidesPermitted = glide.settings.breakpoints[key].perView;
          }
        });
        
        if (size <= numberOfSlidesPermitted) {
          return false;
        }

        glide.on(['mount.after', 'run'], () => {
          this.isLoaded = true;
        });

        glide.mount();
        this.glide = glide;
      }
    }
  },
};
</script>
<style lang="scss" scoped>
// @import "~@storefront-ui/shared/styles/components/organisms/SfCarousel.scss";
@import "~/assets/settings/breakpoints.scss";

.sf-carousel__wrapper{
  --carousel-width: 100%;
  position: relative;

  &.loading{
    .glide__track{

      .glide__slides{
        --gap-count: 7px;
        gap: var(--gap-count);

        @include for-mobile($mobile-s-max){
          --gap-count: 0px;
        }
      }
    }
  }
}

.sf-carousel{
  width: 100%;
}

.carousel-prev-button, .carousel-next-button{
  position: absolute;
  top: 50%;
  margin-top: -25px;
  height: 50px;
  width: 50px;
  padding: 0;

  @include for-mobile($mobile-s-max){
    display: none;
  }
}

.carousel-prev-button{
  left: 0;
}

.carousel-next-button{
  right: 0;
}

.sf-arrow{
  --button-background: var(--c-light-primary-darken);
  --icon-color: var(--c-dark);

  &:hover{
    --button-background: var(--c-dark);
    --icon-color: var(--c-light);
    .sf-icon.color-black{
      --icon-color: var(--c-light);
    }
  }
}

@include for-mobile($mobile-s-max){
  .sf-carousel{
    margin: 0 -16px;
    width: auto;
  }
  .sf-carousel .glide__track, .sf-carousel .glide__slides, .sf-carousel .glide__slide{
    overflow: auto;
  }
  .sf-carousel{
    .sf-carousel__wrapper{
      --carousel-width: 100vw;
      width: var(--carousel-width);
      max-width: var(--carousel-width);
      overflow-y: hidden;
      overflow-x: scroll;
      overflow-x: scroll !important;
      -webkit-overflow-scrolling: touch;
      display: flex;
      justify-content: flex-start;
      pointer-events: all;
      user-select: all;
    }
    .glide, .glide__track{
      width: auto;
      display: inline-block;
    }
    .glide__slides{
      width: auto;
      display: flex;
      flex-wrap: nowrap;
    }
  }
}
</style>
