<template>
  <li class="sf-carousel-item glide__slide">
    <slot />
  </li>
</template>
<script>
export default {
  name: "CarouselItem",
};
</script>

<style lang="scss" scoped>
@import "~/assets/settings/breakpoints.scss";

.glide__slide{
  --column-count: 6;
  --column-space: calc(7px - 1px);
  --mobile-slide-width: 2.3;
  --mobile-slide-items-width: calc(100vw / var(--mobile-slide-width));

  width: calc((100% / var(--column-count)) - var(--column-space));
  max-width: calc((100% / var(--column-count)) - var(--column-space));

  @include for-mobile($desktop-m-min){
    --column-count: 5;
  }
  @include for-mobile($desktop-s-min){
    --column-count: 4;
  }
  @include for-mobile($tablet-min){
    --column-count: 3;
  }
  @include for-mobile($mobile-s-max){
    width: var(--mobile-slide-items-width);
    max-width: var(--mobile-slide-items-width);
  }
}

@include for-mobile($mobile-s-max){
  .glide__slide{
    width: var(--mobile-slide-items-width);
    max-width: var(--mobile-slide-items-width);
    padding: 0;
    & + .glide__slide{
      margin-left: -1px;
    }
  }
}
</style>